import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { OrderSummarySection } from '../components/sections';

const SummaryPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="Order Summary" />
      <OrderSummarySection />
    </Layout>
  );
};

export default SummaryPage;
